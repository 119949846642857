import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <div className="flex flex-wrap justify-center items-center gap-5 pb-5">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F65c17bf5e349e76c96079dbd%2Fstock%2FGLC%20Construction%20LLC-2024-03-04T23%3A11%3A20.818Z-0.jpg?alt=media&token=2398edfb-731c-4fe6-ae57-e189ee670f12"
            alt="Not found"
            width={"450px"}
            data-aos="zoom-in"
            className="rounded"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/interscopemediacrm.appspot.com/o/cambiosImages%2FU25KVWPf59eN9hmhZjWk%2Fimage-1743266576215-1-1043.webp?alt=media&token=191c7279-b07b-490b-9524-6bbb61d5707b"
            alt="Not found"
            width={"450px"}
            data-aos="zoom-in"
            className="rounded"
          />
        </div>
        <div className="flex justify-center items-center pb-5">
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={rpdata?.gmb?.img}
                alt="Not found"
                width={"250px"}
                data-aos="zoom-in"
              />
            </a>
          ) : null}
        </div>
        {rpdata?.dbSocialMedia?.redes.length > 0 ||
        rpdata?.dbSocialMedia?.directorios.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}
        {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5 bg-white rounded p-8`}
        >
          {rpdata?.dbSocialMedia?.directorios.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    key={index}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
          {rpdata?.dbSocialMedia?.redes.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    key={index}
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not Found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default Directories;
